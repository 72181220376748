import React, {useState, useEffect, useRef, forwardRef} from 'react';
import { Element } from 'react-scroll';
import { Link, animateScroll as scroll, scroller } from "react-scroll";
import Headroom from 'react-headroom';
import NavBar from './NavBar';
import Nav from './Nav';
import Intro from './Intro';
import MobileIntro from './MobileIntro';
import About from './About';
import MobileAbout from './MobileAbout';
import Tales from './Tales';
import MobileTales from './MobileTales';
import JoinUs from './JoinUs';
import MobileJoinUs from './MobileJoinUs';
import Footer from './Footer.jsx';
import MobileFooter from './MobileFooter.jsx';
import CareersMain from './careers/CareersMain';
import DesignSystems from './designSystem/Main';
import AboutUs from './aboutUs/App';
import { Helmet } from "react-helmet"
import Skills from './aboutUs/Skills';
import { Switch, Route } from "react-router";
import axios from 'axios';
import cheerio from 'cheerio'; // For parsing HTML


import './App.css';

import Line from './images/navLine.svg';


import {
  StackingLayout
} from '@nutanix-ui/prism-reactjs';


export default function App() {

const [width, setWidth] = useState(window.innerWidth);
const [articles, setArticles] = useState([]);
const sectionFocused = useRef(false);

const isTablet = width >=1024 && width <=1350;


function handleWindowSizeChange() {
  setWidth(window.innerWidth);
}

const isMobile = width <= 768;

function handleScroll() {
  if(!isMobile) {
  const articleLink = document.getElementById('article').getElementsByTagName('a')[0];
  const articleTile = document.getElementsByClassName('articleTile');
  const learnButton = document.getElementById('learnButton');
  setTimeout(() => {
    if(articleLink.classList.contains('active') || document.activeElement === learnButton){
       Array.from(articleTile).forEach((item, i) => {
         articleTile[0].classList.add('animatedTileFirst');
         articleTile[1].classList.add('animatedTileSecond');
         articleTile[2].classList.add('animatedTileThird')
       });

    }
  }, 50);


}
}


const handleKeyDown = (event, navItems) => {

  const currentFocusIndex = Array.from(navItems).indexOf(document.activeElement);
  const introLink = document.getElementById('introLink');
  if (event.key === "ArrowDown") {
  event.preventDefault();
  if (currentFocusIndex < navItems.length - 1) {
    let nextIndex = currentFocusIndex + 1;
    navItems[nextIndex].focus();
    sectionFocused.current = false;
  }
} else if (event.key === "ArrowUp") {
  event.preventDefault();
  if (currentFocusIndex > 0) {
    let prevIndex = currentFocusIndex - 1;
    navItems[prevIndex].focus();
    sectionFocused.current = false;
  }
} else if (event.key === "Tab" && !event.shiftKey) {
     if(!sectionFocused.current) {
     event.preventDefault();
     const introButton = document.getElementById('introExploreButton');
     introButton.focus();
   } else if(sectionFocused.current && introLink.classList.contains('active')) {
     event.preventDefault();
     const introButton = document.getElementById('introExploreButton');
     introButton.focus();
     sectionFocused.current = false;
   }
  }
};

const handleNavigation = (event, targetSection, focusedItem) => {
  if(event.key === "Enter") {
    sectionFocused.current = true;
    window.scroller = scroller;
    setTimeout(() => {
      scroller.scrollTo(targetSection, { duration: 1500,
  delay: 0,
  smooth: true,
  containerId: 'containerElement',
  offset: 50, });
 }, 50);
} else if(event.key === 'Tab' && sectionFocused.current) {

      event.preventDefault();
      focusedItem.focus();
   }

}

const handleTabPress = (e) => {
    if (e.key === 'Tab' && !e.shiftKey) {
      const introLink = document.getElementById('introLink');
       e.preventDefault();
       introLink.focus();

      // Remove this event listener after it runs once

      sectionFocused.current = false;
    }
  };


  useEffect(() => {

    const fetchArticles = async () => {
              try {
                  // Fetch Medium webpage
                  const response = await axios.get('https://us-central1-design-studies.cloudfunctions.net/medium-articles');
                  const items = response.data.rss.channel[0].item.slice(0, -7);
                  let latestArticles = [];
                  items.forEach((item, i) => {
                     let $ = cheerio.load(item['content:encoded'][0]);
                     let article = {};
                     article.img = $('figure')[0].children[0].attribs.src;
                     article.content = $('p')[0].children[0].data;
                     if(!article.content) {
                       article.content = $('p')[0].children[0].children[0].data;
                     }
                     article.title = item.title[0];
                     article.link = item.link[0];
                     latestArticles.push(article);
                  });
                  setArticles(latestArticles);

              } catch (error) {
                  console.error('Error fetching articles:', error);
              }
          };

          fetchArticles();

   document.getElementById('containerElement').addEventListener('scroll', handleScroll);
   if(!isMobile) {
   const articleTile = document.getElementsByClassName('articleTile');
   const learnButton = document.getElementById('learnButton');
   document.getElementById('learnButton').addEventListener('keydown', function(e) {
      Array.from(articleTile).forEach((item, i) => {
        articleTile[0].classList.add('animatedTileFirst');
        articleTile[1].classList.add('animatedTileSecond');
        articleTile[2].classList.add('animatedTileThird')
      });

      if(sectionFocused.current){
      handleTabPress(e);
    }



   });

   if(!isTablet) {
   const navItems = document.querySelectorAll(".focusable-link");



   navItems.forEach((item) => {
     item.setAttribute("tabindex", "0");
     item.addEventListener("keydown", (event) => handleKeyDown(event, navItems));
   });

   const introLink = document.getElementById('introLink');

   introLink.addEventListener("keydown", function(event) {
   if(!introLink.classList.contains('active')) {
   const introButton = document.getElementById('introExploreButton');
   handleNavigation(event, 'introSection', introButton);
   }
   });

   const aboutLink = document.getElementById('aboutLink');

   aboutLink.addEventListener("keydown", function(event) {
    const owlDots = document.getElementsByClassName('owl-dots')[0].querySelector('button');
    handleNavigation(event, 'aboutSection', owlDots);
   });

   const articleLink = document.getElementById('articleLink');

   articleLink.addEventListener("keydown", function(event) {
       const articleParentLink = document.querySelector('.articleParentLink');
       handleNavigation(event, 'articleSection', articleParentLink);
       const lastArticleParentLink = document.querySelectorAll('.articleParentLink')[2];
       lastArticleParentLink.addEventListener('keydown', function(e) {
        if(sectionFocused.current){
          handleTabPress(e);
        }
      });
   });

   const joinUsLink = document.getElementById('joinUsLink');

   joinUsLink.addEventListener("keydown", function(event) {
    const exploreButton = document.getElementById('joinUsExploreButton');
    handleNavigation(event, 'joinUsSection', exploreButton);
    exploreButton.addEventListener('keydown', function(e) {
     if(sectionFocused.current){
       handleTabPress(e);
     }
   });
 });
}
}




   window.addEventListener('resize', handleWindowSizeChange);


   return () => {
       window.removeEventListener('resize', handleWindowSizeChange);
       document.getElementById('containerElement').removeEventListener('scroll', handleScroll);

   }

  }, []);


  return (



    <Element name="test7" className="element" id="containerElement" style={{
      position: 'relative',
      maxHeight: '100vh',
      overflow: 'scroll'
    }}>
    <Helmet>
         <script
           src="https://assets.adobedtm.com/2cd47122fe1d/7b74cf84faa9/launch-834ccd6963ba.min.js"
           crossorigin="anonymous"
           async
         ></script>
       </Helmet>

      <Nav />
      <a href="#maincontent" className="skip-link" id="skipButton">Skip to main content</a>

    <StackingLayout className="mainContainer" id="maincontent" padding="20px" itemSpacing="0px">
    <StackingLayout itemSpacing="0px">


    <Switch>



              <Route
                exact
                path="/skills"
                component={Skills}
              />
              <Route
                exact
                path="/careers"
                component={CareersMain}
              />
              <Route
                exact
                path="/designSystem"
                component={DesignSystems}
              />
              <Route
                exact
                path="/aboutUs"
                component={AboutUs}
              />


       </Switch>
<Headroom style={{
    webkitTransition: 'all .2s ease-in-out',
   mozTransition: 'all .2s ease-in-out',
    oTransition: 'all .2s ease-in-out',
    transition: 'all .2s ease-in-out'
}}>
 <header>
  <NavBar />
    </header>
  </Headroom>
    </StackingLayout>
    <Element id="introSection">
      <section>
      {isMobile ? <MobileIntro/> : <Intro />}
      </section>
    </Element>
    <Element id="aboutSection">
      <section>
      {isMobile ? <MobileAbout/> : <About />}
      </section>
    </Element>
    <Element id="articleSection">
      <section>
      {isMobile ? <MobileTales articles={articles}/> : <Tales articles={articles}/>}
      </section>
    </Element>
    <Element id="joinUsSection">
      <section>
      {isMobile ? <MobileJoinUs/> : <JoinUs />}
      </section>
    </Element>


    </StackingLayout>
    <footer>
    {isMobile ? <MobileFooter/> : <Footer />}
    </footer>
    </Element>



  );
}
